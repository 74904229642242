import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingPage from "./pages/LoadingPage";
import LeadPage from "./pages/LeadPage";
import LandingPage from "./pages/Landing";
import ClothesPage from "./pages/ClothesPage";
import ContactPage from "./pages/Contact";
import BrandPage from "./pages/BrandPage";
import HouseKeepingPage from "./pages/HouseKeeping";
import RecyclePage from "./pages/Recycle";
import ClothesBPage from "./pages/ClothesB";
import ClothesCPage from "./pages/ClothesC";
import TradeMarkPage from "./pages/trademark";
import ClothesDPage from "./pages/ClothesD";
import ClothesEPage from "./pages/ClothesE";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<>Hello World!</>} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/lead" element={<LeadPage />} />
        <Route path="/lead-1" element={<LeadPage category="lead" />} />
        <Route path="/lead-2" element={<LeadPage category="lead" />} />
        <Route path="/lead-3" element={<LeadPage category="lead" />} />
        <Route path="/lead-4" element={<LeadPage category="lead" />} />
        <Route path="/lead-5" element={<LeadPage category="lead" />} />
        <Route path="/lead-6" element={<LeadPage category="lead" />} />
        <Route path="/lead-7" element={<LeadPage category="lead" />} />
        <Route path="/lead-8" element={<LeadPage category="lead" />} />
        <Route path="/clothes-1" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-2" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-3" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-4" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-5" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-6" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-7" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-8" element={<ClothesPage category="clothes" />} />
        <Route path="/brand-1" element={<BrandPage category="brand" />} />
        <Route path="/brand-2" element={<BrandPage category="brand" />} />
        <Route path="/brand-3" element={<BrandPage category="brand" />} />
        <Route path="/brand-4" element={<BrandPage category="brand" />} />
        <Route path="/brand-5" element={<BrandPage category="brand" />} />
        <Route path="/brand-6" element={<BrandPage category="brand" />} />
        <Route path="/brand-7" element={<BrandPage category="brand" />} />
        <Route path="/brand-8" element={<BrandPage category="brand" />} />
        <Route path="/housekeeping-1" element={<HouseKeepingPage category="housekeeping" />} />
        <Route path="/housekeeping-2" element={<HouseKeepingPage category="housekeeping" />} />
        <Route path="/housekeeping-3" element={<HouseKeepingPage category="housekeeping" />} />
        <Route path="/housekeeping-4" element={<HouseKeepingPage category="housekeeping" />} />
        <Route path="/housekeeping-5" element={<HouseKeepingPage category="housekeeping" />} />
        <Route path="/housekeeping-6" element={<HouseKeepingPage category="housekeeping" />} />
        <Route path="/housekeeping-7" element={<HouseKeepingPage category="housekeeping" />} />
        <Route path="/housekeeping-8" element={<HouseKeepingPage category="housekeeping" />} />
        <Route path="/recycle-1" element={<RecyclePage category="recycle" />} />
        <Route path="/recycle-2" element={<RecyclePage category="recycle" />} />
        <Route path="/recycle-3" element={<RecyclePage category="recycle" />} />
        <Route path="/recycle-4" element={<RecyclePage category="recycle" />} />
        <Route path="/recycle-5" element={<RecyclePage category="recycle" />} />
        <Route path="/recycle-6" element={<RecyclePage category="recycle" />} />
        <Route path="/recycle-7" element={<RecyclePage category="recycle" />} />
        <Route path="/recycle-8" element={<RecyclePage category="recycle" />} />
        <Route path="/clothes-b" element={<ClothesBPage category="clothes-b" mode={'audit'} />} />
        <Route path="/clothes-b-1" element={<ClothesBPage category="clothes-b" />} />
        <Route path="/clothes-b-2" element={<ClothesBPage category="clothes-b" />} />
        <Route path="/clothes-b-3" element={<ClothesBPage category="clothes-b" />} />
        <Route path="/clothes-c" element={<ClothesCPage category="clothes-c" mode={'audit'} />} />
        <Route path="/clothes-c-1" element={<ClothesCPage category="clothes-c" />} />
        <Route path="/clothes-c-2" element={<ClothesCPage category="clothes-c" />} />
        <Route path="/clothes-c-3" element={<ClothesCPage category="clothes-c" />} />
        <Route path="/clothes-d" element={<ClothesDPage category="clothes-d" mode={'audit'} />} />
        <Route path="/clothes-d-1" element={<ClothesDPage category="clothes-d" />} />
        <Route path="/clothes-d-2" element={<ClothesDPage category="clothes-d" />} />
        <Route path="/clothes-d-3" element={<ClothesDPage category="clothes-d" />} />
        <Route path="/clothes-e" element={<ClothesEPage category="clothes-e" mode={'audit'} />} />
        <Route path="/clothes-e-1" element={<ClothesEPage category="clothes-e" />} />
        <Route path="/clothes-e-2" element={<ClothesEPage category="clothes-e" />} />
        <Route path="/clothes-e-3" element={<ClothesEPage category="clothes-e" />} />
        <Route path="/clothes-e-4" element={<ClothesEPage category="clothes-e" />} />
        <Route path="/clothes-e-5" element={<ClothesEPage category="clothes-e" />} />

        <Route path="/trademark" element={<TradeMarkPage category="trademark" mode={'audit'} />} />
        <Route path="/trademark-1" element={<TradeMarkPage category="trademark" />} />
        <Route path="/trademark-2" element={<TradeMarkPage category="trademark" />} />
        <Route path="/trademark-3" element={<TradeMarkPage category="trademark" />} />
      </Routes>
    </Router>
  );
}

export default App;
